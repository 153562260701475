import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
// import { generateKey } from '../../util/generateKey'
import { slugify } from '../../util/utilityFunctions'

const Post = ({ title, slug, body, createdAt, tags, ruler }) => (
  <div className="blog-post">
    <h2 className="blog-post-title">
      <Link to={`/blog/${slug}`}>{title}</Link>
    </h2>
    <p className="blog-post-meta">{createdAt}</p>
    <p>{body}</p>
    {/* Read more btn */}
    {/* Tags */}
    <ul className="post-tags">
      {tags.map(tag => (
        <Link key={tag} className="tag" to={`/tag/${slugify(tag)}`}>
          {tag.toUpperCase()}
        </Link>
      ))}
    </ul>
    {ruler && <hr className="horizontal-ruler thin" />}
  </div>
)

Post.prototypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  tags: PropTypes.object.isRequired,
  ruler: PropTypes.bool.isRequired,
}

export default Post
